import CookieBanner from '@/components/CookieBanner';
import Footer from '@/components/Footer';
import Seo from '@/components/Seo';
import { graphql, Link, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import LoginIcon from '../assets/icons/login.svg';
import Logo from '../assets/icons/logo.svg';
import MenuIcon from '../assets/icons/menu.svg';
import '../styles/index.scss';

const Privacy: React.FC<{}> = () => {
  const { t } = useTranslation();
  const [showCookieBanner, toggleCookieBanner] = useState<boolean>(true);
  const [showMobileMenu, toggleShowMobileMenu] = useState<boolean>(false);
  const [hackIndex, setHackIndex] = useState<number>(0);
  const cookieName: string = process.env.GATSBY_GOOGLE_COOKIE_NAME!;
  const [cookies] = useCookies([cookieName]);

  // this is used to counter the behaviour that on mount the document
  // is not defined and the parallax does not trigger
  useEffect(() => {
    setTimeout(() => {
      setHackIndex((old) => old + 1);
    }, 1500);
    toggleCookieBanner(cookies[cookieName] !== 'true');
  }, []);

  return (
    <div className="content-wrapper">
      <Seo />
      {showCookieBanner && (
        <CookieBanner
          onDecline={() => toggleCookieBanner(false)}
          onAccept={() => toggleCookieBanner(false)}
        />
      )}
      <div className="background-curve-top sub-page" />
      <div className="header">
        <MenuIcon
          className="menu hide-on-big"
          onClick={() => toggleShowMobileMenu(true)}
        />
        <Link to="/" className="logo-with-name">
          <Logo />
          <div className="hide-on-small">{t('appName')}</div>
        </Link>
        {showMobileMenu && (
          <div
            className="clickaway"
            onClick={() => toggleShowMobileMenu(false)}
          />
        )}
        <div
          className={`menu-wrapper ${!showMobileMenu && 'disappear'}`}
          onClick={() => toggleShowMobileMenu(false)}
        >
          <a href="/#stempl" className={`menu`}>
            {t('appName')}
          </a>
          <a href="/#configurate" className={`menu`}>
            {t('menu.configuration')}
          </a>
          <a href="/#prices" className={`menu`}>
            {t('menu.prices')}
          </a>
          <span className="login-icon">
            {showMobileMenu && <p>{t('menu.login')}</p>}
            <LoginIcon onClick={() => navigate(process.env.GATSBY_APP_URL!)} />
          </span>
        </div>
      </div>
      <div id="stempl" className="stempl-wrapper sub-page">
        <div className="section-info">
          <div className="stempl-heading">{t('privacy.title')}</div>
        </div>
      </div>

      <div
        className="introduction-wrapper max-content"
        key={`hack-index-${hackIndex}`}
      >
        <div className="content-box">
          <div className="heading">
            § 1 Informationen über die Erhebung personenbezogener Daten
          </div>
          <div className="paragraph">
            Im Folgenden informieren wir im Rahmen unserer Informationspflichten
            nach Art. 13 ff. EU-Datenschutzgrundverordnung (nachfolgend "DSGVO")
            über die Erhebung personenbezogener und Speicherung von Daten im
            Rahmen unseres Service.
          </div>
          <div className="paragraph">
            Dienstleister im direkten Kundenkontakt möchten ihren Kunden den
            bestmöglichen Service bieten. Darunter fällt auch das Anbieten von
            Bonus- oder Loyalty-Programmen. Häufig wird hierbei zu der
            altbewährten Stempel-Karte gegriffen. Gerade im Kontext der
            Digitalisierung und der dadurch einhergehenden Vermeidung von
            unnötigem Müll kann mit Hilfe unseres Services die bewährte
            Stempel-Karte digital genutzt werden.
          </div>
          <div className="paragraph">
            Dienstleister können über unseren Service schnell und einfach ein
            Profil anlegen. Wir gewährleisten eine datenschutzrechtlich konforme
            Speicherung der dafür benötigten Daten und Zahlungsinformationen.
            Die erfassten Daten können jederzeit durch den Kontoinhaber
            eingesehen, aktualisiert und auch gelöscht werden. Der Endkunde kann
            entweder über das von uns angebotene Registrierungs-Formular, oder
            durch den Social Login via Google einen Zugriff auf unseren Service
            erlangen. Auch die in diesem Kontext erhobenen Daten speichern wir
            gleichermaßen datenschutzkonform. Das Erfassen eines digitalen
            Stempels erfolgt über das Scannen eines QR-Codes durch den
            Endkunden. Der Dienstleister kann in seiner Dahboard-Ansicht
            einsehen wie viele Stempel, eingelöste Karten bzw. individuelle
            Kunden bereits verteilt bzw. eingelöst wurden.
          </div>
          <div className="heading">Verantwortliche Stelle</div>
          <div className="paragraph">
            Verantwortliche im Sinne des Art. 4 Abs. 7 DSGVO für die
            personenbezogenen Daten der Website-Besucher (nachfolgend "Sie")
            ist:
          </div>
          <div className="paragraph">
            <strong>DevDuck GmbH</strong>
            <br />
            Leitzstraße 45
            <br />
            70469 Stuttgart
            <br />
            E-Mail: <a href="mailto:info@devduck.de">info@devduck.de</a>
          </div>
          <div className="paragraph">
            Personenbezogene Daten sind alle Daten, die auf Sie persönlich
            beziehbar sind, zum Beispiel Name, Adresse, E-Mail-Adresse.
          </div>
          <div className="heading">
            § 2 Erhebung personenbezogener Daten bei Besuch unserer Website
          </div>
          <div className="heading">
            1. Informatorische Nutzung unserer Website
          </div>
          <div className="paragraph">
            Wenn Sie unsere Website aufrufen, um sie lediglich zu besuchen,
            erheben wir nur die personenbezogenen Daten, die Ihr Browser
            automatisch an unseren Server übermittelt, um einen
            Verbindungsaufbau zu ermöglichen. Folgende Daten werden dadurch
            erhoben:
          </div>
          <div className="paragraph">
            <ul>
              <li>Datum und Uhrzeit des Abrufs unserer Website,</li>
              <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT),</li>
              <li>Inhalt der Anforderung (konkrete Seite),</li>
              <li>jeweils übertragene Datenmenge,</li>
              <li>verwendeter Browsertyp und dessen Version,</li>
              <li>Sprache des verwendeten Browsertyps,</li>
              <li>Betriebssystem, dessen Version und dessen Oberfläche,</li>
              <li>
                die Websites, die vom System des Besuchers über unsere Website
                aufgerufen werden (sog. Referrer),
              </li>
              <li>der Zugriff auf die Internetseite,</li>
              <li>Ihre IP-Adresse,</li>
              <li>Internet Service-Provider des zugreifenden Systems.</li>
            </ul>
          </div>
          <div className="paragraph">
            Dabei wird Ihre IP-Adresse nur dazu verwendet, um zu bestimmen, von
            welchem Land aus Sie auf unsere Website zugreifen. Danach wird die
            IP-Adresse sofort gelöscht. Daher ist eine Zuordnung zu Ihnen nicht
            möglich und die Daten Ihres Websitebesuchs werden auch nicht
            zusammen mit anderen personenbezogenen Daten gespeichert.
          </div>
          <div className="paragraph">
            Die oben genannten Daten sind für uns technisch erforderlich, um
            Ihnen unsere Website anzuzeigen sowie die Stabilität und Sicherheit
            zu gewährleisten. Die Verarbeitung erfolgt auf der Rechtsgrundlage
            des Art. 6 Abs. 1 S. 1 lit. f DSGVO.
          </div>
          <div className="heading">2. Einsatz von Cookies</div>
          <div className="paragraph">
            Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung
            unserer Website Cookies auf Ihrem Rechner gespeichert.
          </div>
          <div className="paragraph">
            Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer
            Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert
            werden und durch welche der Stelle, die den Cookie setzt (hier durch
            uns) bestimmte Informationen zufließen. Cookies können keine
            Programme ausführen oder Viren auf Ihren Computer übertragen. Sie
            dienen dazu, das Internet insgesamt nutzerfreundlicher und
            effektiver zu machen.
          </div>
          <div className="paragraph">
            Wir nutzen hierbei eigene Cookies, um die Funktionalität unserer
            Website zu gewährleisten. Dazu zählen insbesondere die
            Session-Cookies. Diese speichern eine sogenannte Session-ID, mit
            welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen
            Sitzung zuordnen lassen. Die Session-Cookies verfallen nach
            Verlassen unserer Website.
          </div>
          <div className="paragraph">
            Unsere Website benutzt Google Analytics, einen Webanalysedienst der
            Google Inc. (“Google”). Für diesen Webanalysedienst benutzt Google
            Analytics sogenannte „Cookies“, d.h. das Textdateien auf ihrem
            Computer gespeichert werden, die dann wiederum eine Analyse der
            Benutzung der Website durch Sie ermöglichen. Die Informationen, die
            durch den Cookie über die Benutzung unserer Website erzeugt werden,
            werden an einen Server von Google in den USA übertragen und
            anschließend dort gespeichert. Auf unserer Webseite findet eine
            IP-Anonymisierung statt, so wird Ihre IP-Adresse von Google nur
            innerhalb von den Mitgliedstaaten der EU oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            vorher gekürzt. Dabei kann in wenigen Ausnahmefällen die volle
            IP-Adresse an einen Server von Google in den USA übertragen werden
            und dann dort gekürzt werden. Google kann dann aufgrund dieser
            Informationen, die gespeichert werden, Ihre Nutzung der Website
            auswerten, um sodann eine Zusammenstellung eines Reports über die
            Werbseitenaktivitäten bereitzustellen. Aufgrund dieses Reportes kann
            Google dann auch weitere mit der Websitennutzung und der
            Internetnutzung verbundene Dienstleistungen gegenüber dem
            Websiten-Anbieter erbringen. Die über Google Analytics von Ihrem
            Browser übermittelte IP-Adresse wird nicht mit anderen Daten von
            Google zusammen geführt. Liegt diese Auswertung anhand Ihres
            Aufenthaltes auf unserer Seite nicht in Ihrem Interesse, so können
            sie die Speicherung oder Installation der Cookies durch eine
            entsprechende Einstellung Ihrer Browser-Software verhindern.
            Gleichzeitig möchten wir Sie in Kenntnis darüber setzen, dass Sie in
            diesem Fall sämtliche Funktionen unserer Website nicht
            vollumfänglich nutzen können.
          </div>
          <div className="heading">Hinweis:</div>
          <div className="paragraph">
            Sie können durch Ihre Browsereinstellung selbst bestimmen, ob
            Cookies gesetzt und abgerufen werden. Sie können hierbei sich gegen
            den Einsatz von Cookies entscheiden oder die Speicherung nur von
            bestimmten Cookies zulassen. Dort können Sie auch die bereits
            gespeicherten Cookies einsehen und löschen. Allerdings kann die
            Ablehnung von Cookies dazu führen, dass nicht alle Funktionen
            unserer Website zur Verfügung stehen.
          </div>
          <div className="heading">
            Sie haben die Möglichkeit, uns per E-Mail oder Telefon zu
            kontaktieren. Ihre auf diesem Weg übermittelten personenbezogenen
            Daten werden bei uns gespeichert. Eine Weitergabe dieser Daten an
            Dritte erfolgt nicht. Diese Daten werden ausschließlich gespeichert,
            um Ihre Kontaktanfrage bearbeiten zu können.
          </div>
          <div className="paragraph">
            Soweit sich Ihre Anfrage auf eine bestehende Datenspeicherung von
            Ihnen bezieht oder Sie Fragen zu einer beabsichtigten
            Datenspeicherung haben, erfolgt die Datenverarbeitung auf der
            Rechtsgrundlage des Art. 6 Abs. 1 S. 1 lit.b DSGVO. In solchen
            Fällen speichern wir Ihre Daten solange diese zur Durchführung des
            Vertrages erforderlich sind. Darüberhinausgehend speichern wir Ihre
            Daten nur, um vertraglichen oder gesetzlichen Verpflichtungen (z. B.
            steuerlichen Pflichten) nachzukommen (Art. 6 Abs. 1 S. 1 lit.c
            DSGVO).
          </div>
          <div className="paragraph">
            Andernfalls erfolgt die Datenverarbeitung auf der Rechtsgrundlage
            des Art. 6 Abs. 1 S. 1 lit.f DSGVO und in unserem Interesse, um Ihre
            Anfrage mit den für Sie relevanten Informationen beantworten zu
            können. In diesem Fall werden die Daten nur so lange gespeichert,
            bis der Grund Ihre Anfrage sich erledigt hat.
          </div>
          <div className="heading">§ 4 Ihre Rechte</div>
          <div className="paragraph">
            Wenn wir Ihre Daten verarbeiten, sind Sie Betroffener im Sinne der
            DSGVO (Art. 4 Nr. 1 DSGVO). Ihnen stehen folgende Rechte zu:
          </div>
          <div className="paragraph">
            <ul>
              <li>Auskunftsrecht,</li>
              <li>Recht auf Berichtigung,</li>
              <li>Recht auf Einschränkung der Verarbeitung,</li>
              <li>Recht auf Löschung,</li>
              <li>Recht auf Unterrichtung,</li>
              <li>Recht auf Datenübertragbarkeit,</li>
              <li>Widerspruchsrecht,</li>
              <li>Widerrufsrecht,</li>
              <li>das Recht, sich bei der Aufsichtsbehörde zu beschweren.</li>
            </ul>
          </div>
          <div className="heading">Im Einzelnen:</div>
          <div className="heading">1. Auskunftsrecht</div>
          <div className="paragraph">
            Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen,
            ob wir Ihre personenbezogenen Daten verarbeiten; ist dies der Fall,
            so haben Sie das Recht auf Auskunft über die betreffenden
            personenbezogenen Daten und über folgende Informationen:
          </div>
          <div className="paragraph">
            <ul>
              <li>Verarbeitungszweck,</li>
              <li>Kategorie der verarbeiteten personenbezogenen Daten,</li>
              <li>
                die Empfänger oder Kategorien der Empfänger, gegenüber denen die
                personenbezogenen Daten offengelegt werden,
              </li>
              <li>
                falls möglich die geplante Dauer, für die die personenbezogenen
                Daten gespeichert werden,
              </li>
              <li>
                das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
                betreffenden personenbezogenen Daten oder Einschränkung der
                Verarbeitung durch uns oder eines Widerspruchsrechts gegen diese
                Verarbeitung,
              </li>
              <li>
                das Bestehen eins Beschwerderechts bei einer Aufsichtsbehörde.
              </li>
            </ul>
          </div>
          <div className="heading">2. Recht auf Berichtigung</div>
          <div className="paragraph">
            Sie haben das Recht, von uns unverzüglich die Berichtigung und/oder
            die Vervollständigung, der sie betreffenden
            unrichtigen/unvollständigen personenbezogenen Daten zu verlangen.
          </div>
          <div className="heading">3. Recht auf Löschung</div>
          <div className="paragraph">
            Sie haben das Recht, von uns zu verlangen, dass wir Ihre
            personenbezogenen Daten unverzüglich löschen, wenn einer der in Art.
            17 DSGVO genannten Gründe vorliegt, wie z. B. wenn Ihre Daten für
            die Zwecke nicht mehr notwendig sind; wenn die Einwilligung
            widerrufen wurde und keine anderweitige Rechtsgrundlage besteht;
            oder bei unrechtmäßiger Datenverarbeitung.
          </div>
          <div className="heading">
            4. Recht zur Einschränkung der Verarbeitung
          </div>
          <div className="paragraph">
            Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu
            verlangen, wenn eine der in Art. 18 DSGVO aufgeführten
            Voraussetzungen gegeben ist, zum Beispiel wenn Sie Widerspruch gegen
            die Verarbeitung eingelegt haben, für die Dauer der Prüfung durch
            uns.
          </div>
          <div className="heading">5. Recht auf Unterrichtung</div>
          <div className="paragraph">
            Wenn Sie Ihr Berichtigungs-, Löschungs- oder Einschränkungsrecht
            gegenüber uns geltend gemacht haben, müssen wir allen Empfängern,
            denen wir Ihre personenbezogenen Daten offengelegt haben, die
            Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer
            Daten mitteilen, es sei denn, dies erweist sich als unmöglich oder
            ist mit einem unverhältnismäßigen Aufwand verbunden.
          </div>
          <div className="heading">6. Recht auf Datenübertragbarkeit</div>
          <div className="paragraph">
            In Fällen, in denen eine Verarbeitung aufgrund einer Einwilligung
            gemäß Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a) DSGVO oder
            eines Vertrages gemäß Art. 6 Abs. 1 lit. b) DSGVO und mit Hilfe
            automatisierter Verfahren erfolgte, haben Sie das Recht, Ihre
            personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
            strukturierten, gängigen und maschinenlesbaren Format von uns zu
            erhalten. Sie können diese Daten auch an andere Stellen übermitteln
            oder durch uns übermitteln lassen.
          </div>
          <div className="heading">7. Widerspruchsrecht</div>
          <div className="paragraph">
            Wenn wir aufgrund der Anspruchsgrundlage Art. 6 Abs. 1 S. 1 lit. e)
            oder f) DSGVO Ihre personenbezogenen Daten verarbeiten, können Sie
            aus Gründen, die sich aus Ihrer besonderen Situation ergeben,
            jederzeit Widerspruch gegen die Verarbeitung einlegen.
          </div>
          <div className="paragraph">
            Das Widerspruchsrecht haben Sie auch im Falle der Verarbeitung Ihrer
            personenbezogenen Daten zum Zweck der Direktwerbung. Sie können
            jederzeit und ohne Angabe von Gründen diesen widersprechen.
          </div>
          <div className="heading">8. Widerrufsrecht</div>
          <div className="paragraph">
            Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt
            haben, können Sie diese jederzeit mit Wirkung für die Zukunft
            widerrufen.
          </div>
          <div className="heading">
            9. Recht auf Beschwerde bei einer Aufsichtsbehörde
          </div>
          <div className="paragraph">
            Sie haben unbeschadet eines anderweitigen verwaltungsrechtlichen
            oder gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
            Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung
            Ihrer personenbezogenen Daten gegen die DSGVO verstößt.
          </div>
          <div className="paragraph">
            Eine Liste der deutschen Aufsichtsbehörden für den Datenschutz und
            deren Kontaktadressen finden Sie unter:
          </div>
          <div className="paragraph">
            <a
              href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
            </a>
          </div>
          <div className="heading">§ 5 Einsatz von Stripe</div>
          <div className="paragraph">
            Wir bieten die Möglichkeit, den Zahlungsvorgang über den
            Zahlungsdienstleister Stripe, ℅ Legal Process, 510,Townsend St., San
            Francisco, CA 94103 (Stripe) abzuwickeln. Dies entspricht unserem
            berechtigten Interesse, eine effiziente und sichere Zahlungsmethode
            anzubieten (Art. 6 Abs. 1 lit. f DSGVO). In dem Zusammenhang geben
            wir folgende Daten an Stripe weiter, soweit es für die
            Vertragserfüllung erforderlich ist (Art. 6 Abs. 1 lit b. DSGVO):
          </div>
          <div className="paragraph">
            <ul>
              <li>Name des Karteninhabers</li>
              <li>E-Mail-Adresse</li>
              <li>Kundennummer</li>
              <li>Bestellnummer</li>
              <li>Bankverbindung</li>
              <li>Kreditkartendaten</li>
              <li>Gültigkeitsdauer der Kreditkarte</li>
              <li>Prüfnummer der Kreditkarte (CVC)</li>
              <li>Datum und Uhrzeit der Transaktion</li>
              <li>Transaktionssumme</li>
              <li>Name des Anbieters</li>
              <li>Ort</li>
            </ul>
          </div>
          <div className="paragraph">
            Die Verarbeitung der unter diesem Abschnitt angegebenen Daten ist
            weder gesetzlich noch vertraglich vorgeschrieben. Ohne die
            Übermittlung Ihrer personenbezogenen Daten können wir eine Zahlung
            über Stripe nicht durchführen.
          </div>
          <div className="paragraph">
            Stripe nimmt bei Datenverarbeitungstätigkeiten eine Doppelrolle als
            Verantwortlicher und Auftragsverarbeiter ein. Als Verantwortlicher
            verwendet Stripe Ihre übermittelten Daten zur Erfüllung
            regulatorischer Verpflichtungen. Dies entspricht dem berechtigten
            Interesse Stripes (gem. Art. 6 Abs. 1 lit. f DSGVO) und dient der
            Vertragsdurchführung (gem. Art. 6 Abs. 1 lit. b DSGVO). Wir haben
            auf diesen Prozess keinen Einfluss.
          </div>
          <div className="paragraph">
            Als Auftragsverarbeiter fungiert Stripe, um Transaktionen innerhalb
            der Zahlungsnetzwerke abschließen zu können. Im Rahmen des
            Auftragsverarbeitungsverhältnisses wird Stripe ausschließlich nach
            unserer Weisung tätig und wurde im Sinne des Art. 28 DSGVO
            vertraglich verpflichtet, die datenschutzrechtlichen Bestimmungen
            einzuhalten.
          </div>
          <div className="paragraph">
            Stripe hat Compliance-Maßnahmen für internationale
            Datenübermittlungen umgesetzt. Diese gelten für alle weltweiten
            Aktivitäten, bei denen Stripe personenbezogene Daten von natürlichen
            Personen in der EU verarbeitet. Diese Maßnahmen basieren auf den
            EU-Standardvertragsklauseln (SCCs).
          </div>
          <div className="paragraph">
            Weitere Informationen zu Widerspruchs- und Beseitigungsmöglichkeiten
            gegenüber Stripe finden Sie unter:
            <a
              href="https://stripe.com/privacy-center/legal"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://stripe.com/privacy-center/legal
            </a>
          </div>
          <div className="paragraph">
            Ihre Daten werden von uns bis zum Abschluss der Zahlungsabwicklung
            gespeichert. Dazu zählt auch der Zeitraum der für die Bearbeitung
            von Rückerstattungen, Forderungsmanagement und Betrugsprävention
            erforderlich ist.
          </div>
          <div className="heading">
            § 6 Aktualität dieser Datenschutzerklärung
          </div>
          <div className="paragraph">Stand: August 2022</div>
          <div className="spacing" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
